import {ApolloClient, InMemoryCache} from '@apollo/client'
import {createUploadLink} from 'apollo-upload-client'
// eslint-disable-next-line import/no-extraneous-dependencies
import {Decimal} from 'decimal.js'

import type {StrictTypedTypePolicies} from 'generated/apollo-helpers'
import {SITE_URL} from 'settings'

const toDecimal = (v: any) => (v && new Decimal(v)) || v
const typePolicies: StrictTypedTypePolicies = {
    LocationType: {fields: {iva: {read: toDecimal}}},
}
export const apolloClient = new ApolloClient({
    cache: new InMemoryCache({typePolicies}),
    link: createUploadLink({
        uri: `${SITE_URL}/graphql/`,
        credentials: 'include',
    }),
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'cache-and-network',
        },
    },
    ssrMode: typeof window === 'undefined',
})
