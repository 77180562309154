import {createTheme} from '@mui/material/styles'
import {VictoryTheme} from 'victory'

const defaultTheme = createTheme()

export const theme = createTheme({
    shape: {
        borderRadius: 6,
    },
    palette: {
        primary: {
            main: '#00966C',
            light: '#00966C21',
        },
        secondary: {
            main: '#ED8B00',
        },
        error: {
            main: '#B0001F',
            light: '#FDECEA',
        },
        background: {
            default: '#EDEDED',
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    'fontSize': '0.875rem',
                    'lineHeight': 1.43,
                    'letterSpacing': '0.01071em',
                    '& input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active':
                        {
                            '-webkit-box-shadow': ' 0 0 0 30px white inset !important',
                        },
                },
            },
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    padding: defaultTheme.spacing(2),
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: `${defaultTheme.spacing(2)} ${defaultTheme.spacing(3)}`,
                },
            },
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    'border': `1px solid ${defaultTheme.palette.divider}`,
                    'boxShadow': 'none',
                    '@media print': {
                        border: 'none',
                    },
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    '@media print': {
                        padding: 0,
                    },
                },
                content: {
                    'display': 'flex',
                    'alignItems': 'center',
                    'justifyContent': 'space-between',
                    '&.Mui-expanded': {
                        'margin': 0,
                        '@media print': {
                            minHeight: 'unset',
                        },
                    },
                },
                expandIconWrapper: {
                    'paddingTop': 0,
                    'paddingBottom': 0,
                    '@media print': {
                        display: 'none',
                    },
                },
            },
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    'padding': defaultTheme.spacing(2),
                    '@media print': {
                        padding: 0,
                    },
                },
            },
        },
        MuiAccordionActions: {
            styleOverrides: {
                root: {
                    padding: defaultTheme.spacing(2),
                    justifyContent: 'flex-start',
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    '&:last-child > td': {
                        borderBottom: 0,
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    '@media print': {
                        lineHeight: '0.9rem',
                    },
                },
                sizeSmall: {
                    '@media print': {
                        padding: '3px 10px 3px 10px',
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                sizeSmall: {
                    fontSize: '0.875rem',
                },
            },
        },
        MuiAlert: {
            defaultProps: {
                variant: 'filled',
            },
        },
    },
})

type VictoryThemeDefinition = typeof VictoryTheme.material
export const victoryTheme: VictoryThemeDefinition = {
    ...VictoryTheme.material,
    axis: {
        ...VictoryTheme.material.axis,
        style: {
            ...VictoryTheme.material.axis?.style,
            axis: {
                ...VictoryTheme.material.axis?.style?.axis,
                stroke: theme.palette.grey[300],
            },
            grid: {
                ...VictoryTheme.material.axis?.style?.grid,
                stroke: theme.palette.grey[300],
            },
            ticks: {
                ...VictoryTheme.material.axis?.style?.ticks,
                stroke: theme.palette.grey[300],
            },
            tickLabels: {
                ...VictoryTheme.material.axis?.style?.tickLabels,
                fontFamily: theme.typography.fontFamily,
                stroke: theme.palette.grey[500],
            },
        },
    },
    line: {
        ...VictoryTheme.material.line,
        style: {
            ...VictoryTheme.material.line?.style,
            data: {
                ...VictoryTheme.material.line?.style?.data,
                stroke: theme.palette.primary.main,
            },
        },
    },
}
