import {CircularProgress} from '@mui/material'
import {createContext, FC, useContext} from 'react'

import {GraphQLErrors} from 'core/components/graphql-errors'
import {useUserQuery} from 'generated/graphql'

type UserContextType = {
    user?: NonNullable<ReturnType<typeof useUserQuery>['data']>['user']
} & Partial<Pick<ReturnType<typeof useUserQuery>, 'refetch'>>

const UserContext = createContext<UserContextType>({
    user: undefined,
    refetch: undefined,
})

export const UserProvider: FC<React.PropsWithChildren> = ({children}) => {
    const {data, loading, error, refetch} = useUserQuery({
        fetchPolicy: 'cache-first',
    })

    return (
        <UserContext.Provider value={{user: data?.user, refetch}}>
            {error && (
                <GraphQLErrors
                    error={error}
                    refetch={refetch}
                />
            )}
            {loading ? <CircularProgress color='primary' /> : children}
        </UserContext.Provider>
    )
}

export const useUser = () => useContext(UserContext)
