import {FC} from 'react'
import {makeStyles} from 'tss-react/mui'

import * as settings from 'settings'

export const AgcoLogo: FC = () => {
    const {classes} = useStyles()
    return (
        <div>
            <img
                src={require('./assets/agco-logo.png')}
                alt={settings.SITE_NAME}
                className={classes.root}
            />
        </div>
    )
}

const useStyles = makeStyles()(() => ({
    root: {
        maxWidth: '100px',
    },
}))
