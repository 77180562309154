import {useRouter} from 'next/router'
import {useCallback} from 'react'

import {useLogoutMutation} from 'generated/graphql'

export function useLogout() {
    const router = useRouter()

    const [logoutMutation, {client}] = useLogoutMutation()

    const logout = useCallback(async () => {
        await logoutMutation()
        await client.resetStore()
        await router.push('/users/login')
    }, [client, logoutMutation, router])
    return [logout] as const
}
