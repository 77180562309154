import {Trans} from '@lingui/macro'
import {AccountCircle as AccountCircleIcon, Settings} from '@mui/icons-material'
import {Box, Button, CircularProgress, IconButton, Menu, MenuItem, Theme, useMediaQuery} from '@mui/material'
import Link from 'next/link'
import {useRouter} from 'next/router'
import {FC} from 'react'

import CountrySelect from 'components/autocompletes/locale'
import {NotificationBell} from 'components/notifications'
import {useUser} from 'contexts/user'
import {useAnchorElement} from 'core/hooks/use-anchor-element'
import {useLogout} from 'core/hooks/use-logout'
import {useGetLocaleTranslationQuery} from 'generated/graphql'
import * as settings from 'settings'

const UserMenu: FC = () => {
    const router = useRouter()
    const {data, loading} = useGetLocaleTranslationQuery()
    const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
    const [anchorEl, {handleClose, handleOpen}] = useAnchorElement()
    const {user} = useUser()
    const [logout] = useLogout()

    const handleProfile = async () => {
        handleClose()
        router.push('/users/profile')
    }

    const handleLogout = async () => {
        handleClose()
        await logout()
    }

    if (!user) return null
    if (loading) return <CircularProgress />
    if (!data) return null

    return (
        <Box sx={{display: 'flex'}}>
            <CountrySelect />
            {user && user.inAppNotifications && <NotificationBell />}
            {user.isStaff && (
                <IconButton
                    component='a'
                    color='primary'
                    sx={{textDecoration: 'none'}}
                    href='/admin'
                    target='_blank'>
                    <Settings color='primary' />
                </IconButton>
            )}
            {user &&
                (smDown ? (
                    <IconButton
                        onClick={handleOpen}
                        color='primary'
                        size='large'>
                        <AccountCircleIcon />
                    </IconButton>
                ) : (
                    <>
                        <Button
                            onClick={handleOpen}
                            color='primary'
                            startIcon={<AccountCircleIcon />}>
                            {user.displayName}
                            {user.organization && <>&nbsp;{user.organization?.name}</>}
                        </Button>
                    </>
                ))}
            {!user && (
                <Link
                    href={settings.LOGIN_URL}
                    passHref={true}>
                    <Button color='primary'>Login</Button>
                </Link>
            )}
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted={true}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}>
                <li>
                    <MenuItem
                        onClick={handleProfile}
                        component='a'>
                        <Trans>Profile</Trans>
                    </MenuItem>
                </li>
                <MenuItem onClick={handleLogout}>
                    <Trans>Sign out</Trans>
                </MenuItem>
            </Menu>
        </Box>
    )
}

export default UserMenu
