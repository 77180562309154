import {Container, MenuItem, Theme, Typography} from '@mui/material'
import {FC} from 'react'
import {makeStyles} from 'tss-react/mui'

import {TextLink} from 'core/components/text-link'
import {NotificationFragment} from 'generated/graphql'

interface NotificationCardProps {
    notification: NotificationFragment
    onClick: (notification: NotificationFragment) => void
}

export const NotificationMenuItem: FC<React.PropsWithChildren<NotificationCardProps>> = ({notification, onClick}) => {
    const {classes} = useStyles()

    return (
        <TextLink href={notification.path}>
            <MenuItem
                onClick={() => onClick(notification)}
                className={notification.isRead ? classes.isRead : classes.notRead}
                divider={true}>
                <Container
                    maxWidth='sm'
                    className={classes.container}>
                    <Typography
                        component='p'
                        variant='body1'
                        className={classes.message}>
                        {notification.message}
                    </Typography>
                </Container>
            </MenuItem>
        </TextLink>
    )
}

const useStyles = makeStyles()((_theme: Theme) => ({
    container: {
        width: '100vw!important',
    },

    message: {
        wordWrap: 'break-word',
        fontSize: '0.875rem',
    },

    isRead: {
        backgroundColor: '#E8E8E8',
        whiteSpace: 'normal',
    },

    notRead: {
        whiteSpace: 'normal',
    },
}))
