import {useState} from 'react'

export const useAnchorElement = () => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return [anchorEl, {handleOpen, handleClose}] as const
}
