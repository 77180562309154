import {Typography as MuiTypography, TypographyProps as MuiTypographyProps} from '@mui/material'
import {FC} from 'react'
import {makeStyles} from 'tss-react/mui'

export const Typography: FC<Props> = ({fontWeight, underline, ...props}) => {
    const {classes, cx} = useStyles()
    const [boldType, variant] = fontWeight
        ? (fontWeight.split('/') as [BoldTypes, MuiTypographyProps['variant']])
        : [undefined, props.variant]
    return (
        <MuiTypography
            {...props}
            className={cx(props.className || '', {
                [classes.underline]: underline,
                [classes.interactive]: Boolean(props.onClick),
            })}
            variant={variant}
            style={{
                ...props.style,
                fontWeight: (boldType && BoldTypesMap[boldType]) || undefined,
            }}
        />
    )
}

const useStyles = makeStyles()(() => ({
    underline: {
        '&:hover': {
            textDecoration: 'underline',
        },
    },

    interactive: {
        cursor: 'pointer',
    },
}))

const boldTypes = ['light', 'normal', 'semi-bold', 'bold', 'bolder'] as const
type BoldTypes = (typeof boldTypes)[number]

const BoldTypesMap: Record<BoldTypes, number> = {
    'light': 300,
    'normal': 400,
    'semi-bold': 500,
    'bold': 600,
    'bolder': 700,
}

type Props = {
    fontWeight?: `${BoldTypes}/${NonNullable<MuiTypographyProps['variant']>}`
    underline?: boolean
} & MuiTypographyProps
