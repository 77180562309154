import {ApolloError} from '@apollo/client'
import {Alert, AlertTitle, Button, Grid, GridProps, Stack} from '@mui/material'
import {FC} from 'react'

interface GraphQLErrorsProps {
    error?: ApolloError
    refetch?: () => any
    gridProps?: GridProps
}

export const GraphQLErrors: FC<React.PropsWithChildren<GraphQLErrorsProps>> = ({error, refetch, gridProps}) => {
    const refetchButton = refetch && (
        <Button
            color='inherit'
            size='small'
            onClick={() => refetch()}>
            Retry
        </Button>
    )

    const component = (
        <Stack
            direction='column'
            spacing={2}>
            {error?.graphQLErrors.map(({message}) => (
                <Alert
                    key={message}
                    severity='error'
                    action={refetchButton}>
                    <AlertTitle>GraphQL error</AlertTitle>
                    {message}
                </Alert>
            ))}
            {error?.networkError && (
                <Alert
                    severity='error'
                    action={refetchButton}>
                    <AlertTitle>Network error</AlertTitle>
                    {error?.networkError.message}
                </Alert>
            )}
        </Stack>
    )

    if (!error) return null
    if (gridProps) {
        return (
            <Grid
                item={true}
                {...gridProps}>
                {component}
            </Grid>
        )
    }
    return component
}
