import {t, Trans} from '@lingui/macro'
import {NotificationsActiveOutlined, NotificationsNoneOutlined} from '@mui/icons-material'
import {LoadingButton} from '@mui/lab'
import {Badge, Button, CircularProgress, Grid, IconButton, Menu, MenuItem, Typography} from '@mui/material'
import dayjs from 'dayjs'
import Link from 'next/link'
import {FC, useState} from 'react'

import {NotificationMenuItem} from 'components/notifications/notification-menu-item'
import {GraphQLErrors} from 'core/components/graphql-errors'
import {useAnchorElement} from 'core/hooks/use-anchor-element'
import {
    NotificationFragment,
    useGetNotificationsLazyQuery,
    useGetNotificationsUnreadCountQuery,
    useMarkAllNotificationsAsReadMutation,
    useMarkNotificationAsReadMutation,
} from 'generated/graphql'
import {notEmpty} from 'utils'

export const NotificationBell: FC = () => {
    const pollInterval = 1000 * 60
    const [cursor, setCursor] = useState<string>()
    const [markNotificationAsRead] = useMarkNotificationAsReadMutation()
    const [markAllNotificationsAsRead, {loading: loadingMarkAllNotificationsAsRead}] =
        useMarkAllNotificationsAsReadMutation()
    const [notifications, setNotifications] = useState<NotificationFragment[]>([])
    const {data: {unreadCount} = {}} = useGetNotificationsUnreadCountQuery({pollInterval, fetchPolicy: 'network-only'})
    const appendNotifications = (newNotifications: NotificationFragment[]) => {
        setNotifications(prev => {
            const filteredData = prev.filter(
                notification => !newNotifications.some(item => item.id === notification.id),
            )
            return [...newNotifications, ...filteredData].sort((a, b) => dayjs(b.created).diff(dayjs(a.created)))
        })
    }
    const [getNotifications, {refetch, error, loading, data}] = useGetNotificationsLazyQuery({
        onCompleted(data) {
            setCursor(data.notifications?.pageInfo.endCursor)
            const newNotifications = data.notifications?.edges.map(edge => edge.node).filter(notEmpty)
            if (!!newNotifications?.length) appendNotifications(newNotifications)
        },
    })
    const [anchorEl, {handleClose, handleOpen}] = useAnchorElement()
    const open = Boolean(anchorEl)

    const handleMenuOpen = (e: Parameters<typeof handleOpen>[0]) => {
        getNotifications({
            variables: {
                first: 2,
            },
        })
        handleOpen(e)
    }

    const handleMarkNotificationAsRead = async (notification: NotificationFragment, _index: number) => {
        const {data} = await markNotificationAsRead({
            variables: {input: {id: notification.uuid, isRead: true}},
        })
        const newNotification = data?.markNotificationAsRead?.notification
        if (newNotification) {
            appendNotifications([newNotification])
        }
    }

    const handleMarkAllNotificationsAsRead = async () => {
        const {data} = await markAllNotificationsAsRead()
        if (data?.markAllNotificationsAsRead?.status) {
            setNotifications(state => [...state.map(({isRead: _, ...rest}) => ({...rest, isRead: true}))])
        }
    }
    const loadMore = () =>
        getNotifications({
            variables: {
                first: 2,
                after: cursor,
            },
        })

    return (
        <>
            <IconButton
                onClick={handleMenuOpen}
                size='large'>
                <Badge
                    badgeContent={unreadCount}
                    color='error'>
                    {!unreadCount ? (
                        <NotificationsNoneOutlined color='primary' />
                    ) : (
                        <NotificationsActiveOutlined color='primary' />
                    )}
                </Badge>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                elevation={0}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                MenuListProps={{sx: {py: 0}}}
                keepMounted={true}>
                {loading && !error && notifications.length === 0 && <CircularProgress color='primary' />}
                {!loading && error && (
                    <GraphQLErrors
                        error={error}
                        refetch={refetch}
                    />
                )}
                {!error && notifications.length > 0 && (
                    <MenuItem
                        divider={true}
                        sx={{cursor: 'default'}}
                        disableTouchRipple={true}>
                        <Grid
                            container={true}
                            justifyContent='space-between'>
                            <Link
                                href='/users/profile/notifications/edit'
                                style={{textDecoration: 'none'}}>
                                <Button
                                    variant='text'
                                    color='primary'
                                    size='small'>
                                    <Trans>Edit notifications</Trans>
                                </Button>
                            </Link>
                            <Button
                                onClick={handleMarkAllNotificationsAsRead}
                                variant='text'
                                color='primary'
                                disabled={loadingMarkAllNotificationsAsRead}
                                size='small'>
                                <Trans>Mark all as read</Trans>
                            </Button>
                        </Grid>
                    </MenuItem>
                )}
                {notifications.length > 0 &&
                    notifications.map((notification, index) => (
                        <NotificationMenuItem
                            key={notification.id}
                            notification={notification}
                            onClick={() => handleMarkNotificationAsRead(notification, index)}
                        />
                    ))}
                {!error && notifications.length > 0 && data?.notifications?.pageInfo.hasNextPage && (
                    <MenuItem
                        onClick={loadMore}
                        disabled={loading}>
                        <Grid
                            container={true}
                            justifyContent='center'>
                            <Typography
                                variant='subtitle2'
                                color='primary'
                                align='center'>
                                <LoadingButton
                                    fullWidth={true}
                                    color='primary'
                                    loading={loading}
                                    loadingIndicator={t`Loading...`}>
                                    <Trans>Load more</Trans>
                                </LoadingButton>
                            </Typography>
                        </Grid>
                    </MenuItem>
                )}
                {!loading && !error && notifications.length === 0 && (
                    <MenuItem>
                        <Grid
                            container={true}
                            justifyContent='center'>
                            <Typography
                                variant='subtitle1'
                                color='primary'
                                align='center'>
                                <Trans>No pending notifications</Trans>
                            </Typography>
                        </Grid>
                    </MenuItem>
                )}
            </Menu>
        </>
    )
}
