import {t} from '@lingui/macro'
import {
    AttachMoneyRounded as AttachMoneyIcon,
    DescriptionRounded as DescriptionIcon,
    Home as HomeIcon,
    LocationCityRounded as LocationCityIcon,
    PaymentRounded as PaymentOutlinedIcon,
    PersonRounded as PersonIcon,
    SettingsRounded as SettingsIcon,
    TrendingUpRounded as TrendingUpIcon,
} from '@mui/icons-material'
import {List} from '@mui/material'
import React, {FC} from 'react'

import {OrderTrackingIcon} from 'components/icons'
import {useUser} from 'contexts/user'

import AppMenuLink from './app-menu-link'

interface AppMenuProps {
    onClose?(): void
}

const AppMenu: FC<React.PropsWithChildren<AppMenuProps>> = ({onClose}) => {
    const {user} = useUser()

    return (
        <>
            <List
                component='nav'
                sx={{height: '100%', display: 'flex', flexDirection: 'column'}}
                onClick={onClose}>
                <AppMenuLink
                    href='/'
                    label={t`Home`}
                    icon={<HomeIcon />}
                />
                <AppMenuLink
                    href='/quotes'
                    label={t`Quotes`}
                    icon={<AttachMoneyIcon />}
                    permission='viewQuotes'
                />
                <AppMenuLink
                    href='/supply-orders'
                    label={t`Supply orders`}
                    icon={<OrderTrackingIcon />}
                    permission='viewSupplyOrders'
                />
                <AppMenuLink
                    href='/sales'
                    label={t`Sales`}
                    icon={<PaymentOutlinedIcon />}
                    permission='viewSales'
                />
                <AppMenuLink
                    href='/accounts'
                    label={t`Accounts`}
                    icon={<LocationCityIcon />}
                    permission='viewAccounts'
                />
                <AppMenuLink
                    href='/contacts'
                    label={t`Contacts`}
                    icon={<PersonIcon />}
                    permission='viewContacts'
                />
                <AppMenuLink
                    href='/analytics'
                    label={t`Statistics`}
                    icon={<TrendingUpIcon />}
                    permission='viewAnalytics'
                />
                <AppMenuLink
                    href='/warranty-claims'
                    label={t`Warranty Claim`}
                    icon={<DescriptionIcon />}
                    permission='viewWarrantyClaims'
                />
                {user?.isStaff && (
                    <AppMenuLink
                        href='/admin'
                        hrefExternal={true}
                        label={t`Administration`}
                        newTab={true}
                        icon={<SettingsIcon />}
                        sx={{alignItems: 'self-end'}}
                    />
                )}
            </List>
        </>
    )
}

export default AppMenu
