/* eslint-disable @next/next/no-img-element */
import {FormControlLabel, MenuItem, Radio, Select} from '@mui/material'
import Box from '@mui/material/Box'
import getConfig from 'next/config'
import {useMemo} from 'react'

import {useUser} from 'contexts/user'
import {UserDocument, useChangeLanguageMutation} from 'generated/graphql'
import {useI18nContext} from 'i18n'

import {Typography} from '../typography'

export default function CountrySelect() {
    const {user} = useUser()
    const LANGUAGES: [locale: string, country: string][] = getConfig().publicRuntimeConfig.languages || []
    const {setLanguage} = useI18nContext()
    const [changeLanguage] = useChangeLanguageMutation({
        awaitRefetchQueries: true,
        refetchQueries: [{query: UserDocument}],
    })
    const value = useMemo(() => LANGUAGES.find(([locale]) => locale === user?.language)?.[1], [user])
    return (
        <Select
            size='small'
            style={{backgroundColor: '#FFF'}}
            color='primary'
            variant='outlined'
            value={user?.language ?? null}
            renderValue={() => {
                if (!value) return null
                return (
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Typography sx={{ml: 1}}>{value}</Typography>
                    </Box>
                )
            }}
            onChange={async e => {
                if (!e.target.value) return
                await changeLanguage({variables: {language: e.target.value}})
                setLanguage(e.target.value)
            }}>
            {LANGUAGES.map(([locale, country]) => (
                <MenuItem
                    value={locale}
                    key={locale}>
                    <FormControlLabel
                        label={country}
                        value={locale}
                        control={
                            <Radio
                                color='primary'
                                checked={user?.language === locale}
                            />
                        }
                    />
                </MenuItem>
            ))}
        </Select>
    )
}

// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
export const countries = [
    {
        code: 'US',
        label: 'English - EN',
        languageCode: 'EN',
        phone: '1',
        locale: 'en-us',
        suggested: true,
    },
    {code: 'ES', label: 'Español - ES', languageCode: 'ES', phone: '34', locale: 'es-ar'},
]

export type Country = (typeof countries)[number]
