import {i18n} from '@lingui/core'
import {I18nProvider} from '@lingui/react'
import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import minMax from 'dayjs/plugin/minMax'
import utc from 'dayjs/plugin/utc'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import numbro from 'numbro'
import {createContext, PropsWithChildren, useContext, useEffect, useState} from 'react'

import {apolloClient} from 'api'
import {GetLocaleTranslationDocument, GetLocaleTranslationQuery, useUserQuery} from 'generated/graphql'
import * as settings from 'settings'

for (const locale of settings.LOCALES) {
    const {messages} = require(`locales/${locale}.js`)
    i18n.load(locale, messages)
}

dayjs.extend(utc)
dayjs.extend(minMax)
dayjs.extend(weekOfYear)
dayjs.extend(isSameOrBefore)

numbro.setDefaults({
    mantissa: Number(settings.MAX_DECIMALS),
    thousandSeparated: true,
})

/**
 * Load messages for requested locale and activate it.
 * This function isn't part of the LinguiJS library because there are
 * many ways how to load messages — from REST API, from file, from cache, etc.
 */
export async function loadCatalogFromApi(locale: string) {
    const {data} = await apolloClient.query<GetLocaleTranslationQuery>({query: GetLocaleTranslationDocument})
    const locales = data.localeTranslations
        .filter(({locale}) => settings.LOCALES.some(l => l === locale))
        .map(locale => ({
            ...locale,
            json: JSON.parse(locale.json),
        }))
    const _locale = locales.find(({locale: l}) => l === locale)
    return _locale?.json || {}
}
export async function loadCatalog(locale: string) {
    const catalog = await import(`./locales/${locale}.js`)
    return catalog.messages
}

interface CustomI18nContextProps {
    setLanguage(lang: string): void
    currentLang: string
}

const CustomI18nContext = createContext<CustomI18nContextProps>({
    setLanguage: () => {},
    currentLang: 'en-us',
})

export const useI18nContext = () => useContext(CustomI18nContext)

export const CustomI18nProvider = ({initialLanguage, children}: PropsWithChildren<{initialLanguage?: string}>) => {
    /* 
    FIXME: https://github.com/lingui/js-lingui/issues/1078 bug where you must rerender all the app when language switches 
    if you are using   t``
    */
    const {data: {user} = {}} = useUserQuery({
        fetchPolicy: 'no-cache',
    })

    const [language, setLanguage] = useState(() => user?.language ?? initialLanguage ?? 'en-us')

    useEffect(() => {
        i18n.activate(language)
        dayjs.locale(language)
        numbro.setLanguage(language)
    }, [language])

    useEffect(() => {
        if (user?.language) {
            setLanguage(user.language)
        }
    }, [user])

    if (!i18n.locale) return null
    return (
        <I18nProvider i18n={i18n}>
            <CustomI18nContext.Provider
                value={{
                    setLanguage: lang => {
                        setLanguage(lang)
                        if (typeof window !== 'undefined') window.localStorage.setItem('lang', lang)
                    },
                    currentLang: language,
                }}>
                {children}
            </CustomI18nContext.Provider>
        </I18nProvider>
    )
}
